import React from "react"
import Layout from "../../components/Layout"
import EnrollmentForm from "../../components/EnrollmentForm"
import course from '../../data/Course.json'
import Banner from "../../components/Banner"

const RegisterComponent = (props) => {
    let orderId = ''
    if (props && props.orderId) {
        orderId = props.orderId
    }
    const buyCourse = () => {
        let cart = {
            id: course.id,
            slug: course.slug,
            title: course.title,
            description: course.shortDescription,
            image: course.image,
            priceInternational: course.priceInternational,
            priceCurrency: course.currency,
            price: course.price,
            cartCurrency: course.currency,
            cartAmount: course.price,
            hsnRate: course.hsnRate,
            hsnCode: course.hsnCode,
            taxIncluded: course.taxIncluded,
            orderId: ""
        }
        if (course.priceOptions && course.priceOptions.length > 0) {
            cart.priceOptions = course.priceOptions;
            cart.price = 0;
            for (const price of cart.priceOptions) {
                if (price.isDefault && (price.amount > cart.price)) {
                    cart.price = price.amount;
                    cart.priceCurrency = price.currency;
                    cart.cartAmount = price.amount;
                    cart.cartCurrency = price.currency;
                    cart.taxIncluded = price.taxIncluded;
                }
            }
        }
        if (typeof window !== 'undefined') {
          window.localStorage.setItem('cart', JSON.stringify(cart));
        }
        return cart
    }

  return (
    <Layout>
        <Banner />
        <section className="pb-0 py-lg-2">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card shadow rounded-2 p-0 pt-2 align-items-center">
                            <div className="row">
                                <div className="col-12 text-center mx-auto">
                                    <h1 className="mb-4">Enroll in the course!</h1>
                                    <h6>Please provide us the details below to enroll in this course</h6>
                                </div>
                            </div>
                            <div className="row mx-auto d-flex justify-content-center">
                                <div className="col-12 col-md-9">
                                    <EnrollmentForm cart={buyCourse()} orderId={orderId} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </Layout>
  )
}

export default RegisterComponent